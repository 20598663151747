
import { defineComponent, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";

interface forgotPass {
  email: string;
}

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const toast = useToast();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email('Email Harus Valid').required('Email Wajib Diisi'),
    });

    const forgotPass = reactive<forgotPass>({
      email: "",
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request

        ApiService
        .PostMethodWithoutData("crmv2/main_auth/auth/forgot_password?email=" + forgotPass.email)
        .then((res) => {

          Swal.fire({
            text: "Silahkan cek email anda untuk mendapatkan link verifikasi",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, saya cek email",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

        });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      forgotPass,
    };
  },
});
